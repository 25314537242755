<template>
  <input
    :id="id"
    v-model="modelValue"
    :name="name ?? id"
    :type="type"
    :class="
      classes(
        'block w-full rounded-lg border border-gray-300 px-3 py-2 text-lg focus:border-primary focus:ring-primary',
        { 'bg-white text-gray-900': !disabled },
        { 'cursor-not-allowed bg-gray-200 text-gray-500': disabled },
        extraClass
      )
    "
    :placeholder="placeholder"
    :required="required"
    :disabled="disabled"
    @blur="isBlured = true"
  />
  <div v-if="isBlured && required && !modelValue" class="mt-1 text-red-500">
    Ce champ est obligatoire
  </div>
</template>

<script lang="ts" setup>
withDefaults(
  defineProps<{
    id: string;
    name?: string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    type?: string;
    extraClass?: string;
  }>(),
  {
    name: undefined,
    placeholder: undefined,
    type: 'text',
    extraClass: undefined
  }
);

const isBlured = ref(false);
const modelValue = defineModel<object | string | number>();
</script>
